import { Model } from "lib/oom";
import { isNil } from "lib/util";
import Account from "../Account";

/**
 * Decorator that overrides create to make sure account_id is set from Account.current
 */
export function setAccountId<C extends { new (...args: any[]): Model<any> & { account_id?: string } }>(
  constructor: C,
  context?: ClassDecoratorContext,
): any {
  return class extends constructor {
    override async create() {
      if (isNil(this.account_id) && Account.current?.id) {
        this.account_id = Account.current?.id;
      }
      return super.create();
    }
  };
}

import CloseIcon from "assets/icons/close.svg";
import clsx from "clsx";
import { Button } from "components/Controls/Button";
import ApplicationController, { ApplicationView } from "lib/mvc";
import { useEffect, useRef } from "react";

const MESSAGE_TIMEOUT = 4000;

export type MessageType = "error" | "success" | "info" | "warning";
export interface AppMessage {
  body: React.ReactNode;
  type: MessageType;
  fading?: boolean;
}

interface State {
  messages: AppMessage[];
}

export class MessagesController extends ApplicationController<State> {
  static override initialState: State = {
    messages: [],
  };

  actionAddMessage(body: React.ReactNode, type: MessageType) {
    const message = { body, type };
    this.state.messages.push(message);
    setTimeout(() => {
      const index = this.state.messages.length - 1;
      this.actionAcknowledgeMessage(index);
    }, MESSAGE_TIMEOUT);
  }

  actionClearMessages() {
    this.state.messages = [];
  }

  actionAcknowledgeMessage(index: number) {
    this.state.messages[index].fading = true;
  }

  actionDeleteMessage(index: number) {
    this.state.messages.splice(index, 1);
  }
}

const messageColors = {
  error: "bg-rose-400",
  success: "bg-green-200",
  info: "bg-blue-400",
  warning: "bg-yellow-400",
} as const;

const Message = ApplicationView(({ message, index }: { message: AppMessage; index: number }) => {
  const controller = MessagesController.use();
  const el = useRef<HTMLDivElement>(null);
  const color = messageColors[message.type];

  useEffect(() => {
    if (!el.current) return;
    if (message.fading) {
      const animation = el.current.animate([{ marginTop: "0px" }, { marginTop: "-200px" }], {
        duration: 500,
      });
      animation.finished.then(() => {
        controller.actionDeleteMessage(index);
      });
    }
  }, [message.fading]);

  return (
    <div
      ref={el}
      className={clsx(
        "w-full rounded-md p-4 shadow shadow-neutral-400 transition duration-500 ease-in-out",
        "flex justify-between",
        color,
        "text-black",
        {
          "opacity-0": message.fading,
          "opacity-100": !message.fading,
        },
      )}
    >
      <div>
        {message?.body}
        {message?.type === "error" && (
          <span>
            {" "}
            Please contact <a href="mailto:support@mixitone.com">support@mixitone.com</a>
          </span>
        )}
      </div>
      <Button kind="plain" onClick={() => controller.actionAcknowledgeMessage(index)}>
        <CloseIcon className="w-[30px] fill-gray-600 hover:fill-gray-800" />
      </Button>
    </div>
  );
});

const Messages: React.FC = () => {
  const controller = MessagesController.use();
  const { messages } = controller.state;

  return (
    <div className="fixed left-0 right-0 top-0 z-50 mx-5 mt-10 md:mx-auto md:w-[600px]">
      <div className="flex flex-col items-center justify-center gap-5">
        {messages.map((message, index) => (
          <Message key={`message-${index}`} message={message} index={index} />
        ))}
      </div>
    </div>
  );
};

export default ApplicationView(Messages);

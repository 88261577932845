import { Spinner } from "components/Controls/Spinner";
import { omit } from "lib/util";
import React from "react";
import { twMerge } from "tailwind-merge";
import { Button, ButtonKind, buttonClasses } from "./Button";
import RouteController from "controllers/RouteController";
import tauri from "lib/tauri";

interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode;
  kind?: ButtonKind;
  spinner?: boolean;
  className?: string;
  groupPosition?: React.ComponentProps<typeof Button>["groupPosition"];
}

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const classes = buttonClasses(props.kind || "primary", twMerge(props.className, ""));
  const controller = RouteController.use();

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (tauri.enabled) {
      event.preventDefault();
      controller.actionForward(props.href!);
    }
  };

  return (
    <a
      {...omit(props, ["spinner", "groupPosition"])}
      onClick={props.onClick || handleClick}
      className={classes}
      ref={ref}
    >
      <span className="inline-flex items-center gap-1">
        {props.spinner && <Spinner size={16} />}
        {!props.spinner && props.children}
      </span>
    </a>
  );
});

export default Link;

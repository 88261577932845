import { observable } from "@nx-js/observer-util";
import { Model, Query } from "lib/oom";

type SortableRecord = {
  id: string | undefined;
};

export default class List<Item extends SortableRecord> extends Array<Item> {
  private _comparator: Parameters<Array<Item>["sort"]>[0] | null = null;
  private _loading: boolean;

  constructor(...items: Item[]);
  constructor(query: Query<Item & Model<any>, any>);
  constructor(...itemsOrQuery: unknown[]) {
    if (itemsOrQuery.length === 1 && itemsOrQuery[0] instanceof Query) {
      super();
      this._loading = true;
      const self = observable(this);
      const query = itemsOrQuery[0];
      query.all().then((items) => {
        self.push(...items);
        self.resort();
        this._loading = false;
      });
      return self;
    } else {
      super(...(itemsOrQuery as Item[]));
      this._loading = false;
    }
    return observable(this);
  }

  get loading() {
    return this._loading;
  }

  findIndexById(id: string) {
    return this.findIndex((item) => item.id === id);
  }

  findById(id: string) {
    return this.find((item) => item.id === id);
  }

  orderBy(column: keyof Item, direction: "asc" | "desc" = "asc") {
    const comparator = (a: Item, b: Item) => {
      const aValue = a[column];
      const bValue = b[column];
      if (aValue === bValue) return 0;
      return aValue > bValue ? 1 : -1;
    };

    return this.sortBy(comparator, direction);
  }

  sortBy(comparator: Parameters<Array<Item>["sort"]>[0], direction: "asc" | "desc" = "asc") {
    if (comparator && direction === "desc") {
      this._comparator = (a: Item, b: Item) => -comparator(a, b);
    } else {
      this._comparator = comparator;
    }
    this.resort();
  }

  insert(item: Item) {
    if (item.id && this.findById(item.id)) {
      return this.replace(item);
    }

    this.push(item);
    this.resort();
  }

  replace(item: Item) {
    if (!item.id) throw new Error("item must have an id");

    const index = this.findIndexById(item.id);
    if (index === -1) return;
    this[index] = item;
    this.resort();
  }

  remove(item: Item) {
    if (!item.id) throw new Error("item must have an id");

    return this.removeById(item.id);
  }

  removeById(id: string) {
    const index = this.findIndexById(id);
    if (index === -1) return;
    this.splice(index, 1);
  }

  swap(fromIndex: number, toIndex: number) {
    const [item] = this.splice(fromIndex, 1);
    this.splice(toIndex, 0, item);
  }

  override splice(start: number, deleteCount?: number | undefined): Item[];
  override splice(start: number, deleteCount: number, ...items: Item[]): Item[];
  override splice(start: unknown, deleteCount?: unknown, ...rest: unknown[]): Item[] {
    const result = super.splice(start as number, deleteCount as number, ...(rest as Item[]));
    this.resort();
    return result;
  }

  resort() {
    if (!this._comparator) return;
    this.sort(this._comparator);
  }

  setItems(items: Item[]) {
    this.splice(0, this.length, ...items);
  }
}

import { Model, ModelAttributes, model, modelConfig, reference } from "lib/oom";
import { Loadable } from "../oom/reference";
import Night from "./Night";
import ClubPlayer from "./ClubPlayer";
import { setAccountId } from "./concerns/SetAccountId";

const config = modelConfig(
  {
    account_id: {
      type: "string",
    },
    active: {
      type: "boolean",
    },
    club_player_id: {
      type: "string",
    },
    created_at: {
      type: "datetime",
    },
    index: {
      type: "number",
    },
    is_deleted: {
      type: "boolean",
    },
    name: {
      type: "string",
    },
    night_id: {
      type: "string",
    },
    paid: {
      type: "boolean",
    },
    partner_id: {
      type: "string",
    },
    updated_at: {
      type: "datetime",
    },
  },
  {},
  {
    night: reference<any>(() => Night as any, "night_id"),
    clubPlayer: reference<any>(() => ClubPlayer as any, "club_player_id"),
  },
);

interface Player extends ModelAttributes<typeof config> {
  night: Loadable<Night>;
  clubPlayer: Loadable<ClubPlayer>;
}

@setAccountId
@model("players", config)
class Player extends Model<typeof config> {
  get group() {
    return this.clubPlayer.group;
  }

  get timesPlayed() {
    return this.night.game_sets.filter((gs) => gs.started && gs.hasPlayer(this.id!)).length;
  }

  get timesOut() {
    return this.night.game_sets.filter((gs) => gs.started).length - this.timesPlayed;
  }

  get gameSets() {
    return this.night.game_sets.filter((gs) => gs.hasPlayer(this.id!));
  }

  get games() {
    return this.gameSets.flatMap((gs) => gs.games).filter((g) => g.hasPlayer(this.id!));
  }

  get name() {
    if (!this.clubPlayer.loaded) throw new Error("Club player not loaded");
    return this.clubPlayer.name;
  }

  set name(value: string | undefined) {
    if (!this.clubPlayer.loaded) throw new Error("Club player not loaded");
    this.clubPlayer.name = value;
    this.writeAttribute("name", value);
  }

  get isPaired() {
    return !!this.partner_id;
  }
}

export type PlayerPair = [Player, Player];
export type PlayerSingle = [Player];
export type PlayerContainer = PlayerPair | PlayerSingle;
export type PlayerPairs = PlayerContainer[];
export function isPlayerPair(players: PlayerContainer | Player): players is PlayerPair {
  return Array.isArray(players) && Player.is(players[0]) && players.length === 2;
}
export function isPlayerContainer(players: any): players is PlayerContainer {
  return isPlayerPair(players) || (Array.isArray(players) && Player.is(players[0]));
}

export default Player;

import { observable } from "@nx-js/observer-util";
import dayjs from "dayjs";
import Debug from "debug";
import { capitalize, entries, isNil } from "lib/util";
import { SubscriptionManager } from "./SubscriptionManager";
import { DatabaseAdapter } from "./adapters/DatabaseAdapter";
import { Collection, CollectionDescriptor, createCollection } from "./collection";
import {
  AnyModelConfig,
  AssignableModelFields,
  Attributes,
  ModelAttributes,
  ModelConfig,
  ModelFields,
} from "./config";
import { ModelRegistry } from "./modelRegistry";
import { InsertQuery, Query, UpdateQuery } from "./query";
import { Loadable, ReferenceDescriptor, loadableReference } from "./reference";
import requestQueue from "./requestQueue";

let uniqueId = 0;

function isModelConstructor<M extends Model<any>>(value: any): value is ModelConstructor<M> {
  return "tableName" in value;
}

/**
 * @param model The model of the collection. This can take a function returning
 * the model if it needs deferring due to import/load order
 * @param key
 * @param options
 * @returns
 */
export function collection<M extends Model<any>, C extends GetModelConfig<M> = GetModelConfig<M>>(
  model: ModelConstructor<M> | (() => ModelConstructor<M>),
  key: keyof ModelFields<C> & string,
  options: { order?: string } = {},
): CollectionDescriptor<M> {
  let refModel = isModelConstructor(model) ? () => model : model;
  return { model: refModel, key, ...options };
}

export function reference<M extends Model<any>>(
  model: () => ModelConstructor<M>,
  key: string,
): ReferenceDescriptor<M> {
  return { model, key };
}

export type GetModelConfig<M extends Model<any>> =
  M extends Model<infer C> ? (C extends ModelConfig<any, any, any> ? C : never) : never;

export type GetModelAttributes<M extends Model<any>> =
  M extends Model<infer C> ? (C extends AnyModelConfig ? ModelAttributes<C> : never) : never;

export type ModelConstructor<M extends Model<any>> = {
  adapter: DatabaseAdapter;
  tableName: string;
  config: GetModelConfig<M>;
  query(): Query<any, GetModelConfig<M>>;
  hasField(key: string): boolean;
  new (attributes: AssignableModelFields<GetModelConfig<M>>): M;
};

export class Model<C extends AnyModelConfig> {
  static tableName: string = "";
  static config: AnyModelConfig;
  // static supabase: SupabaseClient<Database>;
  static adapter: DatabaseAdapter;

  static query<M extends Model<any>>(this: ModelConstructor<M>): Query<M, GetModelConfig<M>> {
    return new Query(this, { adapter: this.adapter });
  }

  static create<M extends Model<any>>(
    this: ModelConstructor<M>,
    attributes: ModelAttributes<GetModelConfig<M>>,
  ) {
    return new InsertQuery(this, { adapter: this.adapter }).insert(attributes);
  }

  static hasField(key: string): boolean {
    return key in this.config.fields;
  }

  /**
   * Given a set of models this will save them all in a single promise.
   */
  static async saveBatch<M extends Model<any>>(batch: M[]): Promise<void> {
    const toCreate = batch.filter((record) => !record.persisted);
    const toUpdate = batch.filter((record) => record.persisted);

    await Promise.all([this.createBatch(toCreate), this.updateBatch(toUpdate)]);
  }
  /**
   * Given a set of models this will update them all in a single promise.
   */
  static async updateBatch<M extends Model<any>>(batch: M[]): Promise<void> {
    if (batch.some((record) => !record.persisted)) {
      throw new Error("Record in batch is not persisted so cannot be updated");
    }

    await Promise.all(batch.map((record) => record.save()));
  }
  /**
   * Given a set of models this will create them all in a single promise.
   */
  static async createBatch<M extends Model<any>>(batch: M[]): Promise<M[]> {
    if (batch.some((record) => record.persisted)) {
      throw new Error("Record in batch is already persisted so cannot be created");
    }
    await Promise.all(batch.map((record) => record.save()));
    return batch;
  }

  static is<M extends ModelConstructor<any>>(this: M, model: any): model is InstanceType<M> {
    return model instanceof Model && model.tableName === this.tableName;
  }

  dirtyAttributes: Set<keyof ModelFields<C> & string> = new Set();
  rawAttributes: Record<string, any> = {};
  private _attributes: Map<keyof ModelFields<C> & string, any> = new Map();
  private _data: Map<string, any> = new Map();

  get attributes() {
    return this._attributes;
  }

  get data() {
    return this._data;
  }

  private _id: string | undefined = undefined;
  private setId(id: string | undefined) {
    if (id !== undefined && typeof id !== "string") {
      throw new Error(`Invalid id: ${id}, id must be a string`);
    }
    this._id = id;
  }
  get id(): string | undefined {
    return this._id;
  }
  domKey(...suffixes: Array<string | number>) {
    return `${suffixes.join("-")}-${this.tableName}-${this.uniqueId}`;
  }

  private _uniqueId: number = uniqueId++;
  get uniqueId() {
    if (this.persisted) return this.id!;
    return String(this._uniqueId);
  }

  canEdit() {
    return true;
  }
  canDestroy() {
    return this.canEdit();
  }

  private debug(...args: any[]) {
    // @ts-ignore
    Debug(`oom:model:${this.constructor.name}`)(...args);
  }

  get fields(): C["fields"] {
    // @ts-ignore
    return this.constructor.config.fields;
  }

  get collections(): C["collections"] {
    // @ts-ignore
    return this.constructor.config.collections;
  }

  get references(): C["references"] {
    // @ts-ignore
    return this.constructor.config.references;
  }

  get tableName(): string {
    // @ts-ignore
    return this.constructor.tableName;
  }

  constructor(attributes: AssignableModelFields<C>) {
    if ("attributes" in attributes) {
      attributes = attributes.attributes as any;
    }

    this.defineFields();
    this.defineCollections();
    this.defineReferences();
    this.setAttributes(attributes);

    const model = observable(this);
    if (this.persisted) {
      ModelRegistry.instance.register(model as any);
    }
    return model;
  }

  setAttributes(attributes: Attributes<C>) {
    this.rawAttributes = attributes;
    this.setId(isNil(attributes.id) ? undefined : String(attributes.id));
    this._attributes = new Map();
    this._data = new Map();
    this.dirtyAttributes = new Set();

    entries(attributes).forEach(([key, value]) => {
      if (typeof key !== "string") return;

      if (key === "id" || isNil(value) || value === undefined) {
        return;
      }

      if (this.fields[key]) {
        this.attributes.set(key, value);
        // Dirty for new records
        if (!this.persisted) this.dirtyAttributes.add(key);
      } else if (key in this.references) {
        const ref = this.references[key] as ReferenceDescriptor<any>;
        const model = new (ref.model())(value);
        this._references[key].set(model);
      } else {
        this.data.set(key, value);
      }
    });
  }

  /**
   * Update the attributes of the model without marking them as dirty
   */
  updateAttributes(attributes: Partial<Attributes<C>>) {
    this.rawAttributes = { ...this.rawAttributes, ...attributes };
    entries(attributes).forEach(([key, value]) => {
      if (typeof key !== "string") return;

      if (key === "id" || isNil(value) || value === undefined) {
        return;
      }

      if (this.fields[key]) {
        this.attributes.set(key, value);

        if (this._refKeys[key]) {
          const ref = this._references[this._refKeys[key]];
          if (ref.loaded && ref.id !== String(value)) {
            ref.reloadIfLoaded();
          }
        }
      } else {
        this.data.set(key, value);
      }

      this.dirtyAttributes.delete(key);
    });
  }

  get dirty() {
    return this.dirtyAttributes.size > 0;
  }

  get persisted() {
    return !!this.id;
  }

  defineFields() {
    Object.keys(this.fields).forEach((key) => {
      Object.defineProperty(this, key, {
        get() {
          return this.readAttribute(key);
        },
        set(v) {
          this.writeAttribute(key, v);
        },
        configurable: true,
      });
    });
  }

  private _collections: Record<string, Collection<any>> = {};

  readCollection(key: string): Collection<any> {
    return this._collections[key];
  }

  defineCollections() {
    Object.keys(this.collections).forEach((key) => {
      this._collections[key] = createCollection(this, key, this.collections[key]);

      Object.defineProperty(this, key, {
        get() {
          return this._collections[key];
        },
      });
      Object.defineProperty(this, `load${capitalize(key)}`, {
        get() {
          return (values: any[]) => {
            const collection = createCollection(this, key, this.collections[key]);
            collection.push(...values);
            collection.loaded = true;
            this._collections[key] = collection;
          };
        },
      });
    });
  }

  private _references: Record<string, Loadable<any>> = {};
  private _refKeys: Record<string, string> = {};

  readReference(key: string): Loadable<any> {
    return this._references[key];
  }

  defineReferences() {
    Object.keys(this.references).forEach((key) => {
      const ref = this.references[key] as ReferenceDescriptor<any>;
      const model = ref.model();
      this._references[key] = loadableReference(this, model, ref.key as any, true);
      this._refKeys[ref.key] = key;

      Object.defineProperty(this, key, {
        get() {
          return this._references[key];
        },
        set(v) {
          this._references[key].set(v);
          this.writeAttribute(ref.key, v?.id);
        },
      });
    });
  }

  readAttribute<K extends keyof ModelFields<C>>(key: K & string): ModelFields<C>[K] | undefined {
    const adapter = (this.constructor as ModelConstructor<this>).adapter;
    const field = this.fields[key];
    let value: any = adapter.convertType(field || { type: "string" }, this.attributes.get(key));

    switch (field?.type) {
      case "json": {
        const jsonMarkers = ["{", "["];

        if (isNil(value)) {
          value = { ...this.fields[key].jsonType };
        } else if (typeof value === "string" && value.length > 0 && jsonMarkers.includes(value[0])) {
          try {
            return JSON.parse(value);
          } catch (e) {
            return undefined as any;
          }
        }

        return value;
      }
      case "datetime":
        if (!isNil(value)) {
          const dateValue = value as string;
          const [_date, time] = dateValue.split("T");
          let dateTime;

          if (!time) {
            dateTime = dayjs(`${dateValue}T00:00Z`).startOf("day").toDate();
          } else {
            if (time.includes("+") || time.includes("-") || time.includes("Z")) {
              dateTime = dayjs(dateValue).toDate();
            } else {
              dateTime = dayjs(`${dateValue}Z`).toDate();
            }
          }

          return dateTime as any;
        } else {
          return undefined;
        }
      case "string":
        if (!isNil(value)) {
          return String(value) as any;
        }
      case "boolean":
        if (typeof value === "string") {
          // @ts-ignore
          return value === "true";
        }
      case "number": {
        if (typeof value === "string") {
          return Number(value) as any;
        }
      }
    }

    if (isNil(value)) return undefined;
    return value;
  }

  writeAttribute<K extends keyof ModelFields<C>>(key: K & string, value: ModelFields<C>[K]) {
    const oldValue = this.attributes.get(key);

    if (this.fields[key].type === "datetime" && !isNil(value)) {
      if (!(value instanceof Date)) {
        throw new Error(`Tried assiging a non-date to date field ${String(key)}`);
      }

      this.attributes.set(key, (value as Date).toISOString());
    } else if (isNil(value)) {
      this.attributes.set(key, null);
    } else {
      this.attributes.set(key, value);
    }

    if (this.attributes.get(key) !== oldValue || this.fields[key].type === "json") {
      this.dirtyAttributes.add(key);

      if (this._refKeys[key]) {
        const ref = this._references[this._refKeys[key]];
        if (ref.id !== value) ref.reloadIfLoaded();
      }
    }
  }

  changes() {
    const changes: Partial<ModelFields<C>> = {};
    this.dirtyAttributes.forEach((key) => {
      changes[key] = this.attributes.get(key as string);
    });
    return changes;
  }

  markClean() {
    this.dirtyAttributes.clear();
  }

  async update(force?: boolean) {
    if (!this.persisted) throw new Error("Cannot update without an id");
    if (force) {
      this.debug("forcing update", this.tableName, this.id);
    } else if (this.dirty) {
      this.debug("updating because dirty", this.tableName, this.id, this.dirtyAttributes);
    } else {
      this.debug("skipping update, no changes");
    }
    if (!force && !this.dirty) return;

    if (this.fields["updated_at"]) {
      // @ts-expect-error cannot know updated_at details in superclass
      this.writeAttribute("updated_at", new Date());
    }

    const attributes = Object.fromEntries(this.attributes) as Partial<ModelFields<C>>;
    const updateQuery = new UpdateQuery(this.constructor as ModelConstructor<this>, {
      adapter: Model.adapter,
    }).eq("id", this.id);

    await requestQueue.add(async () => await updateQuery.set(attributes));

    this.markClean();
    SubscriptionManager.instance.emit(this.tableName, "UPDATE", {
      id: this.id,
      ...attributes,
    });
  }

  async create() {
    if (this.persisted) throw new Error("Already created");

    if (this.fields["updated_at"]) {
      // @ts-expect-error cannot know updated_at details in superclass
      this.writeAttribute("updated_at", new Date());
    }

    // Attributes we're sending
    const attributes = Object.fromEntries(this.attributes) as Partial<ModelFields<C>>;
    // We want to do a select on the attributes we're not sending and the id to
    // make sure we get defaults
    const undefinedFields = Object.keys(this.fields).filter((name) => attributes[name] === undefined);
    undefinedFields.push("id");

    const insertQuery = new InsertQuery(this.constructor as ModelConstructor<this>, {
      adapter: Model.adapter,
    }).select(...undefinedFields);

    const insertedModel = (await requestQueue.add(async () => await insertQuery.insert(attributes))) as this;

    // Rebuild the old and new attributes
    const newAttributes = { ...attributes, ...insertedModel.rawAttributes };
    this.setAttributes(newAttributes);
    this.markClean();
    ModelRegistry.instance.register(this as any);
    SubscriptionManager.instance.emit(this.tableName, "INSERT", { ...newAttributes });
  }

  async destroy() {
    if (!this.persisted) throw new Error("Cannot destroy without an id");

    const updateQuery = new UpdateQuery(this.constructor as ModelConstructor<this>, {
      adapter: Model.adapter,
    }).eq("id", this.id);

    await requestQueue.add(async () => await updateQuery.delete());

    ModelRegistry.instance.unregister(this as any);
    SubscriptionManager.instance.emit(
      this.tableName,
      "DELETE",
      {},
      {
        id: this.id,
      },
    );
  }

  async save() {
    if (this.persisted) return this.update();
    return this.create();
  }

  async reload() {
    if (!this.persisted) throw new Error("Cannot reload without an id");
    const reloadedModel = await (this.constructor as ModelConstructor<any>).query().find(this.id!);
    this.setAttributes(reloadedModel.rawAttributes);
  }
}

/**
 * A class decorator that takes the model class and adds the given model table and config
 */
export function model<T extends string>(tableName: T, config: AnyModelConfig) {
  return function <C extends { new (...args: any[]): Model<any> }>(
    constructor: C,
    context?: ClassDecoratorContext,
  ): any {
    return class extends constructor {
      static tableName: T = tableName;
      static config = config;
    };
  };
}

import tauri from "lib/tauri";
import { tryJsonParse } from "lib/util";
import { CollectionDescriptor, Field, InsertQuery, Query, UpdateQuery } from "..";
import { ReferenceDescriptor } from "../reference";
import { BoundQuery } from "./BoundQuery";
import { CountResponse, DataResponse, DatabaseAdapter } from "./DatabaseAdapter";
import QuerySqlGenerator from "./QuerySqlGenerator";

export function escapeColumnName(column: string) {
  return `"${column}"`;
}

export default class TauriDatabaseAdapter extends DatabaseAdapter {
  constructor() {
    super();
  }

  convertType(field: Field, value: any) {
    if (field.type === "json" && typeof value === "string") {
      if (value === "") return null;
      return tryJsonParse(value);
    }
    if (field.type === "number" && typeof value === "string") {
      return Number(value);
    }
    if (field.type === "boolean" && typeof value === "number") {
      return value !== 0;
    }

    return value;
  }

  private invoke_tauri<T>(type: "select" | "insert" | "update" | "delete", args: any) {
    return tauri.invoke<T>(`plugin:mixitonedb|${type}`, args);
  }

  invoke_select(query: string, values: any[]) {
    return this.invoke_tauri<any[]>("select", { query, values });
  }

  private invoke_insert(table: string, columns: string[], values: any[]) {
    return this.invoke_tauri<Array<{ id: string }>>("insert", { table, columns, values });
  }

  private invoke_update(
    table: string,
    columns: string[],
    values: any[],
    whereClause: string,
    whereValues: any[],
  ) {
    return this.invoke_tauri("update", { table, columns, values, whereClause, whereValues });
  }

  private invoke_delete(table: string, whereClause: string, whereValues: any[]) {
    return this.invoke_tauri("delete", { table, whereClause, whereValues });
  }

  async preloadCounts(query: Query<any, any>, data: any[]) {
    if (!query.options.preloadCounts) return;

    for (const collection of query.options.preloadCounts) {
      const collectionConfig = query.modelClass.config.collections[collection] as CollectionDescriptor<any>;

      const countSql = BoundQuery.blank()
        .push("SELECT COUNT(*) as count, ")
        .push(escapeColumnName(collectionConfig.key))
        .push(" as id")
        .push(" FROM ")
        .push(collectionConfig.model().tableName)
        .push(" WHERE ")
        .push(escapeColumnName(collectionConfig.key))
        .push(" IN ")
        .push_bind(
          data.map((row) => row.id),
          "string",
        )
        .push(" GROUP BY ")
        .push(escapeColumnName(collectionConfig.key));

      const counts = await this.invoke_select(...countSql.asArgs);

      data.forEach((row) => {
        const count = counts.find((count: { id: string }) => count.id === row.id)?.count || 0;
        row[collection] = [{ count }];
      });
    }
  }

  async preloadReferences(query: Query<any, any>, data: any[]) {
    if (!query.preloadReferences) return;
    for (const reference of query.preloadReferences) {
      const referenceConfig = query.modelClass.config.references[reference] as ReferenceDescriptor<any>;
      const referenceIds = data.map((row) => row[referenceConfig.key]);
      const referenceQuery = referenceConfig.model().query().in("id", referenceIds);
      const referenceSql = new QuerySqlGenerator(referenceQuery).toSql();
      const referenceData = await this.invoke_select(...referenceSql.asArgs);
      const indexedReferenceData = referenceData.reduce((acc, row) => {
        acc[row.id] = row;
        return acc;
      }, {});

      data.forEach((row) => {
        row[reference] = indexedReferenceData[row[referenceConfig.key]];
      });
    }
  }

  override async execute<Data>(query: Query<any, any>): Promise<DataResponse<Data>> {
    const sqlGenerator = new QuerySqlGenerator(query);
    const sql = sqlGenerator.toSql();

    try {
      const data = (await this.invoke_select(...sql.asArgs)) as any[];

      if (data.length > 0) {
        await this.preloadCounts(query, data);
        await this.preloadReferences(query, data);
      }

      const response = { data };
      return response;
    } catch (e) {
      console.error("Database select error:", sql, e);
      throw e;
    }
  }

  override async count(query: Query<any, any>): Promise<CountResponse> {
    const sqlGenerator = new QuerySqlGenerator(query);
    const sql = sqlGenerator.toSql(true);

    try {
      const counts = await this.invoke_select(...sql.asArgs);
      return {
        count: counts[0].count,
      };
    } catch (e) {
      console.error("Database count error:", e, sql.sql);
      throw e;
    }
  }

  override async insert<Data>(
    query: InsertQuery<any, any>,
    data: Record<string, any>,
  ): Promise<DataResponse<Data>> {
    const columns = Object.keys(data);
    const values = Object.values(data);

    columns.push("id");
    values.push("uuid()");

    if (!columns.includes("is_deleted")) {
      columns.push("is_deleted");
      values.push("false");
    }

    try {
      const response = await this.invoke_insert(query.tableName, columns, values);

      const selection = query.selection.map((column) => escapeColumnName(column)).join(", ");
      const selectSql = BoundQuery.blank()
        .push("SELECT ")
        .push(selection)
        .push(" FROM ")
        .push(query.tableName)
        .push(" WHERE id = ")
        .push_bind(response[0].id, "string");
      const selectResponse = await this.invoke_select(...selectSql.asArgs);

      return { data: selectResponse[0] };
    } catch (e) {
      console.error("Database insert error:", e);
      throw e;
    }
  }

  async update<Data>(query: UpdateQuery<any, any>, data: Record<string, any>): Promise<DataResponse<Data>> {
    const entries = Object.entries(data);
    const values = entries.map(([_, value]) => value);
    const sqlGenerator = new QuerySqlGenerator(query);
    const whereClause = sqlGenerator.toWhere();
    try {
      const response = await this.invoke_update(
        query.tableName,
        entries.map(([column]) => column),
        values,
        ...whereClause.getOrElse(BoundQuery.blank()).asArgs,
      );
      console.log({ response });
      return { data: null };
    } catch (e) {
      console.error("Database update error:", e);
      return { data: null, error: e as Error };
    }
  }

  async delete(query: UpdateQuery<any, any>): Promise<CountResponse> {
    if (Object.keys(query.filter).length === 0) {
      throw new Error("Tauri does not allow deleting all records");
    }

    const sqlGenerator = new QuerySqlGenerator(query);

    try {
      const whereClause = sqlGenerator.toWhere();
      const response = await this.invoke_delete(
        query.tableName,
        whereClause.getOrElse(BoundQuery.blank()).build(),
        [],
      );

      return { count: 1, error: null };
    } catch (e) {
      console.error("Database delete error:", e);
      return { count: 0, error: e as Error };
    }
  }
}

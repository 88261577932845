import clsx from "clsx";
import { NullErrorBoundary } from "components/NullErrorBoundary";
import tauri from "lib/tauri";
import React, { ReactNode } from "react";
import Link from "../Controls/Link";
import Brand from "../Pages/Home/Brand";

const wwwUrl = import.meta.env.VITE_WWW;

interface SplitScreenLayoutProps {
  showBack?: boolean;
  onBack?: () => void;
  title?: ReactNode;
  children: ReactNode;
  rightChildren?: ReactNode;
}

const SplitScreenLayout: React.FC<SplitScreenLayoutProps> = ({
  children,
  rightChildren,
  onBack,
  title,
  showBack,
}) => {
  const reallyShowBack = showBack ?? true;

  const handleOnBack = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (onBack) {
      e.preventDefault();
      onBack();
    }
  };

  return (
    <div className={clsx("h-screen bg-brand-600", { "sm:p-10": !tauri.enabled })}>
      <div className="flex flex-col h-full shadow-2xl shadow-slate-700 sm:flex-row">
        <div
          data-tauri-drag-region
          className={clsx(
            "flex w-auto bg-slate-800 bg-gradient-to-r from-slate-700 to-slate-800 sm:w-1/3 sm:flex-col sm:bg-gradient-to-b lg:w-1/2",
            { "sm:rounded-l-lg": !tauri.enabled },
          )}
        >
          <div className="p-4" data-tauri-drag-region></div>
          <div className="flex items-center justify-center flex-grow" data-tauri-drag-region>
            <div className="hidden sm:block">
              {rightChildren || <Brand className="text-4xl" color="dark" />}
            </div>
            <div className="sm:hidden">
              <Brand className="text-3xl" color="dark" />
            </div>
          </div>
          <div className="w-8 sm:hidden"></div>
        </div>
        <div className="flex flex-col flex-grow bg-white sm:w-2/3 lg:w-1/2">
          <a
            href={wwwUrl}
            onClick={handleOnBack}
            className={clsx("ml-4 mt-4 w-8 text-xl text-slate-800", {
              invisible: !reallyShowBack,
            })}
          >
            ←
          </a>
          <div
            className={clsx(
              "flex h-full flex-grow flex-col justify-center bg-white sm:flex-grow-0 sm:items-center",
              { "sm:rounded-r-lg": !tauri.enabled },
            )}
          >
            <div className="w-full p-10 ms:px-20 sm:px-16">
              {title && <h1 className="p-0 mb-10 text-4xl text-gray-400">{title}</h1>}
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SplitScreenLayout;

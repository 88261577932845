import {
  flip,
  offset,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
} from "@floating-ui/react";
import { clsx } from "clsx";
import { useCounter } from "components/hooks/useCounter";
import { ApplicationView } from "lib/mvc";
import tauri from "lib/tauri";
import React, { PropsWithChildren, useCallback } from "react";
import { Dropdown, DropdownController } from "./Dropdown";

interface MenuItemProps<T = undefined> {
  href?: string;
  onClick?: (value: T) => void;
  closeOnClick?: boolean;
  className?: string;
  value?: T;
}

function DropdownMenuItemComponent<T>(props: PropsWithChildren<MenuItemProps<T>>) {
  const { value, children, href, onClick, closeOnClick, className } = props;
  const controller = DropdownController.use();

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      if (onClick) {
        event.preventDefault();
        // Value can be forced because we allow undefined
        onClick(value!);
        if (closeOnClick) controller.actionClose();
      } else {
        controller.actionClose();

        if (tauri.enabled) {
          event.preventDefault();
          controller.actionForward(href!);
        }
      }
    },
    [onClick],
  );

  if (href || onClick) {
    return (
      <a
        href={href || "#"}
        onClick={handleClick}
        className={clsx(
          "block whitespace-nowrap px-4 py-2 text-gray-800 hover:bg-brand-100 hover:text-gray-800",
          className,
        )}
      >
        {children}
      </a>
    );
  } else {
    return <div className={clsx("block px-4 py-2 text-gray-800", className)}>{children}</div>;
  }
}

interface SubmenuProps {
  label: React.ReactNode;
  className?: string;
}

const DropdownSubmenuComponent: React.FC<PropsWithChildren<SubmenuProps>> = ({
  label,
  children,
  className,
}) => {
  const { value: hovered, increment: handleMouseEnter, decrement: handleMouseLeave, reset } = useCounter();
  const [isOpen, setIsOpen] = React.useState(false);
  const { refs, context, floatingStyles } = useFloating({
    placement: "right-start",
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset({ crossAxis: -10, mainAxis: 0 }), flip(), shift()],
  });
  const hover = useHover(context, { handleClose: safePolygon() });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([hover, dismiss]);

  return (
    <div
      className={clsx(
        "whitespace-nowrap px-4 py-2 text-gray-800 hover:bg-brand-100 hover:text-gray-800",
        className,
      )}
      ref={refs.setReference}
      {...getReferenceProps()}
    >
      <div className="flex items-center justify-between gap-5">
        <span>{label}</span>
        <span className="scale-75">▶</span>
      </div>
      <DropdownMenu
        open={isOpen}
        onClose={() => setIsOpen(false)}
        dropRef={refs.setFloating}
        floatingProps={getFloatingProps()}
        style={floatingStyles}
        portal={false}
      >
        {children}
      </DropdownMenu>
    </div>
  );
};

const DropdownMenu = Dropdown;
const DropdownMenuItem: typeof DropdownMenuItemComponent = ApplicationView(DropdownMenuItemComponent) as any;
const DropdownSubmenu = ApplicationView(DropdownSubmenuComponent);
const DropdownMenuSeparator = () => <div className="border-t border-gray-200" />;

export { DropdownMenu, DropdownMenuItem, DropdownMenuSeparator, DropdownSubmenu };

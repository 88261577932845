import { Some, type Maybe } from "lib/maybe";
import { isNil } from "lib/util";
import { BaseQuery, Collections, Field, Query, QueryFilter, SelectableQuery, WhereableQuery } from "..";
import { References } from "../reference";
import { BoundQuery } from "./BoundQuery";
import { escapeColumnName } from "./TauriDatabaseAdapter";

function columnFqdn(tableName: string, column: string): string {
  return `${tableName}.${escapeColumnName(column)}`;
}

export default class QuerySqlGenerator {
  constructor(
    private query: Query<any, any> | (BaseQuery<any, any> & SelectableQuery & WhereableQuery<any>),
  ) {}

  toWhere(): Maybe<BoundQuery> {
    const query = this.query;
    const filter: QueryFilter<any> = query.filter || {};

    if (isNil(filter["is_deleted"])) {
      filter["is_deleted"] = { op: "eq", value: false };
    }

    const fields = query.modelClass.config.fields;
    const boundQuery = new BoundQuery("");

    Object.entries(filter).forEach(([key, filterValue], index) => {
      if (!filterValue) return;

      const field = fields[key] as Field | undefined;
      const colType = field?.type;
      const { op, value } = filterValue;
      const qnKey = `${query.tableName}.${key}`;

      if (index > 0) {
        boundQuery.push(" AND ");
      }

      // Always false
      if (op === "in" && value.length === 0) {
        boundQuery.push("1 = 0");
        return;
      }

      boundQuery.push(qnKey);
      boundQuery.push(" ");

      switch (op) {
        case "eq":
          boundQuery.push("=");
          break;
        case "neq":
          boundQuery.push("!=");
          break;
        case "gt":
          boundQuery.push(">");
          break;
        case "gte":
          boundQuery.push(">=");
          break;
        case "lt":
          boundQuery.push("<");
          break;
        case "lte":
          boundQuery.push("<=");
          break;
        case "in":
          boundQuery.push("IN");
          break;
        default:
          throw new Error(`Unsupported operator: ${op}`);
      }

      boundQuery.push(" ");
      boundQuery.push_bind(value, colType);
    });

    return Some(boundQuery);
  }

  get countClause() {
    const clause = BoundQuery.blank().push("SELECT ");
    clause.push("COUNT(*) as count");
    clause.push(" FROM ").push(this.query.tableName);
    return clause;
  }

  get fromClause() {
    return BoundQuery.sql("FROM ").push(this.query.tableName);
  }

  get selectClause() {
    const selection = this.query.selection.map(
      (column) => this.query.tableName + "." + escapeColumnName(column) + " as " + escapeColumnName(column),
    );

    if (this.query instanceof Query) {
      this.query.joinQueries.forEach((joinQuery) => {
        joinQuery.selection.forEach((column) => {
          selection.push(
            joinQuery.tableName +
              "." +
              escapeColumnName(column) +
              " as " +
              escapeColumnName(`${joinQuery.tableName}!${column}`),
          );
        });
      });
    }

    const selectClause = BoundQuery.sql("SELECT ");
    selectClause.push(selection.join(", "));
    selectClause.merge(this.fromClause);
    return selectClause;
  }

  get orderByClause() {
    const orderByClause = BoundQuery.blank();
    if (this.query instanceof Query && this.query.order) {
      orderByClause
        .push("ORDER BY ")
        .push(columnFqdn(this.query.tableName, this.query.order.column))
        .push(" ")
        .push(this.query.order.direction.toUpperCase());
    }
    return orderByClause;
  }

  get joinClause() {
    const query = this.query;
    const joinQueries: BoundQuery[] = [];

    if (query instanceof Query && query.joinQueries && query.joinQueries.length > 0) {
      query.joinQueries.forEach((join) => {
        // Determine the join direction and construct the ON clause
        let onClause = "";

        const references = query.modelClass.config.references as References;
        const collections = query.modelClass.config.collections as Collections;

        const referenceEntry = Object.entries(references).find(
          ([_, ref]) => ref.model().tableName === join.tableName,
        );
        const collectionEntry = Object.entries(collections).find(
          ([_, col]) => col.model().tableName === join.tableName,
        );

        if (referenceEntry) {
          const [referenceKey, reference] = referenceEntry;
          onClause = `${query.tableName}.${reference.key} = ${join.tableName}.id`;
        } else if (collectionEntry) {
          const [collectionKey, collection] = collectionEntry;
          onClause = `${join.tableName}.${collection.key} = ${query.tableName}.id`;
        } else {
          throw new Error(`Could not find a reference or collection to join on: ${join.tableName}`);
        }

        const whereClause = new QuerySqlGenerator(join).toWhere().getOrElse(BoundQuery.blank());

        joinQueries.push(
          BoundQuery.blank()
            .push("INNER JOIN ")
            .push(join.tableName)
            .push(" ON ")
            .merge(whereClause)
            .push(whereClause.isBlank ? "" : " AND ")
            .push(onClause),
        );
      });
    }

    return joinQueries.reduce((acc, joinQuery) => acc.merge(joinQuery), BoundQuery.blank());
  }

  get limitClause() {
    const query = this.query;
    if (query instanceof Query && query.options.limit) {
      return BoundQuery.sql("LIMIT ").push(String(query.options.limit));
    }
    return BoundQuery.blank();
  }

  get whereClause() {
    return this.toWhere()
      .map((where) => BoundQuery.sql("WHERE").merge(where))
      .getOrElse(BoundQuery.blank());
  }

  toSql(count = false): BoundQuery {
    const boundQuery = count ? this.countClause.clone() : this.selectClause.clone();

    boundQuery.merge(this.joinClause);
    boundQuery.merge(this.whereClause);

    if (!this.orderByClause.isBlank) {
      boundQuery.merge(this.orderByClause);
    }

    boundQuery.merge(this.limitClause);

    return boundQuery;
  }
}

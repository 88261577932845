import Link from "components/Controls/Link";
import StartIcon from "components/icons/StartIcon";
import SessionController from "controllers/SessionController";
import { routeHelpers } from "controllers/routes";
import { ApplicationView } from "lib/mvc";

export const HomeMenu = ApplicationView(() => {
  const controller = SessionController.use();

  return (
    <div className="flex w-full justify-end pl-2 pr-3">
      <div className="flex items-center gap-3 self-end">
        {!controller.signedIn && (
          <Link href={routeHelpers.freeSession({})} className="gap-1 self-end">
            <span>Start for free</span>
            <StartIcon />
          </Link>
        )}
      </div>
    </div>
  );
});

import { useCallback, useState } from "react";

export const useCounter = () => {
  const [value, setValue] = useState(0);

  const increment = useCallback(() => {
    setValue((c) => c + 1);
  }, []);
  const decrement = useCallback(() => {
    setValue((c) => c - 1);
  }, []);
  const reset = useCallback(() => {
    setValue(0);
  }, []);

  return { value, increment, decrement, reset };
};

const brandColors = {
  light: ["text-gray-500", "text-slate-800", "text-cyan-700"],
  dark: ["text-slate-200", "text-slate-100", "text-cyan-300"],
} as const;

const Brand: React.FC<{
  className?: string;
  color?: keyof typeof brandColors;
}> = ({ className, color }) => {
  const colors = brandColors[color || "light"];

  return (
    <h1 className={`sm:leading-none ${className}`}>
      <span className="block">
        <span className={colors[0]}>Mix</span>
        <span className={colors[1]}>It</span>
        <span className={colors[2]}>One</span>
      </span>
    </h1>
  );
};

export default Brand;

import dayjs from "dayjs";
import { Database } from "lib/database.types";
import { Model, ModelAttributes, model, modelConfig } from "lib/oom";
import { createSupabase } from "lib/supabaseClient";

const config = modelConfig(
  {
    accepted_by_id: {
      type: "string",
    },
    account_id: {
      type: "string",
    },
    created_at: {
      type: "datetime",
    },
    created_by_id: {
      type: "string",
    },
    email: {
      type: "string",
    },
    is_deleted: {
      type: "boolean",
    },
    role: {
      type: "string",
    },
    status: {
      type: "enum",
      enumType: "Pending" as Database["public"]["Enums"]["invite_status"],
    },
    token: {
      type: "string",
    },
    updated_at: {
      type: "datetime",
    },
    user_id: {
      type: "string",
    },
  },
  {},
  {},
);

interface Invite extends ModelAttributes<typeof config> {}

@model("invites", config)
class Invite extends Model<typeof config> {
  get canCancel(): boolean {
    return this.status === "Pending" || this.status === "Sent";
  }

  get expired(): boolean {
    if (!this.created_at) return true;
    return dayjs().diff(dayjs(this.created_at), "day") > 7;
  }

  async cancel() {
    if (!this.canCancel) throw new Error("Invite is not pending");

    this.status = "Cancelled";
    await this.save();
    await this.destroy();
  }

  async accept() {
    // TODO: should be OK, but need to represent this cannot happen from the tauri app
    const response = await createSupabase().functions.invoke("accept-invite", {
      body: JSON.stringify({ response: "Accept", token: this.token }),
    });
    if (response.error) return false;
    return true;
  }

  async reject() {
    const response = await createSupabase().functions.invoke("accept-invite", {
      body: JSON.stringify({ response: "Decline", token: this.token }),
    });
    if (response.error) return false;
    return true;
  }
}

export default Invite;

import ApplicationController from "./mvc";

/**
 * A decorator that wraps all the methods starting with `action` in a class wit a logger
 */
export default function actionLogger(
  originalClass: { new (...args: any[]): ApplicationController<any> },
  context?: ClassDecoratorContext
): any {
  const keys = Object.getOwnPropertyNames(originalClass.prototype);
  const actions = keys.filter((key) => key.startsWith("action"));
  actions.forEach((action) => {
    const descriptor = Object.getOwnPropertyDescriptor(
      originalClass.prototype,
      action
    );
    if (descriptor) {
      Object.defineProperty(originalClass.prototype, action, {
        ...descriptor,
        value: function (...args: any[]) {
          this.debug(`Action: ${action}(`, args, `)`);
          return descriptor.value.apply(this, args);
        },
      });
    }
  });
}

import { DropdownMenuItem, DropdownMenuSeparator } from "components/Controls/DropdownMenu";
import DropdownButton from "components/Controls/DropdownButton";
import UserIcon from "assets/icons/user.svg";
import { AppController } from "controllers/AppController";
import { routeHelpers } from "controllers/routes";
import { ApplicationView } from "lib/mvc";
import { useCallback } from "react";
import Pulse from "components/Controls/Pulse";
import clsx from "clsx";

const UserMenu = () => {
  const appController = AppController.use();
  const { updateAvailable } = appController.state;

  const handleLogout = useCallback(() => {
    appController.actionSignOut();
  }, [appController]);

  const handleUpdater = useCallback(() => {
    appController.actionCheckForUpdates();
  }, [appController]);

  return (
    <DropdownButton
      className="user-menu"
      buttonComponent="button"
      button={
        <div className="relative">
          <Pulse
            className={clsx({
              "opacity-0": !updateAvailable,
              "opacity-100": updateAvailable,
            })}
          />
          <UserIcon className="w-[34px] stroke-white hover:stroke-slate-300" />
        </div>
      }
      dropdownTitle="Signed in"
      arrow
    >
      <DropdownMenuItem value="profile" href={routeHelpers.profile({})}>
        My profile
      </DropdownMenuItem>
      <DropdownMenuItem value="account" href={routeHelpers.account({})}>
        Account settings
      </DropdownMenuItem>
      <DropdownMenuSeparator />
      <DropdownMenuItem
        value="updater"
        onClick={handleUpdater}
        closeOnClick
        className="flex items-center gap-2"
      >
        <div>{updateAvailable ? "New version available" : "Check for updates"}</div>
        {updateAvailable && <Pulse className="relative mt-0" />}
      </DropdownMenuItem>
      <DropdownMenuSeparator />
      <DropdownMenuItem value="signOut" onClick={handleLogout}>
        Sign out
      </DropdownMenuItem>
    </DropdownButton>
  );
};

export default ApplicationView(UserMenu);

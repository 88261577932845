import Home from "components/Pages/Home/Home";
import NotFound from "components/Pages/NotFound/NotFound";
import { NotFoundError } from "lib/oom/errors";
import { Component, ErrorInfo } from "react";

interface ErrorState {
  path: string;
  error?: Error;
  errorInfo?: ErrorInfo;
}
interface ErrorProps {
  path: string;
  children: React.ReactNode;
}

export class ErrorBoundary extends Component<ErrorProps, ErrorState> {
  constructor(props: ErrorProps) {
    super(props);
    this.state = { path: props.path };
  }

  override componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  override componentDidUpdate(
    prevProps: Readonly<ErrorProps>,
    prevState: Readonly<ErrorState>,
    snapshot?: any,
  ): void {
    if (this.props.path !== prevProps.path) {
      this.setState({
        path: this.props.path,
        error: undefined,
        errorInfo: undefined,
      });
    }
  }

  override render() {
    if (this.state.error) {
      if (this.state.error instanceof NotFoundError) {
        return <NotFound />;
      }

      console.error('unhandled error:', this.state.error);
      return <div>An error occurred: {this.state.error?.message}</div>
    } else {
      return <>{this.props.children}</>;
    }
  }
}

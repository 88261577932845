import { Database } from "lib/database.types";
import { Model, ModelAttributes, model, modelConfig, reference } from "lib/oom";
import { Loadable } from "lib/oom/reference";
import { isNil } from "lib/util";
import Account from "./Account";
import { SessionAdapter, User } from "lib/session/SessionAdapter";
import SupabaseDatabaseAdapter from "lib/oom/adapters/SupabaseDatabaseAdapter";

const config = modelConfig(
  {
    account_id: {
      type: "string",
    },
    created_at: {
      type: "datetime",
    },
    is_deleted: {
      type: "boolean",
    },
    name: {
      type: "string",
    },
    role: {
      type: "enum",
      enumType: "Admin" as Database["public"]["Enums"]["user_role"],
    },
    updated_at: {
      type: "datetime",
    },
    user_id: {
      type: "string",
    },
  },
  {},
  {
    account: reference<any>(() => Account as any, "account_id"),
  },
);

interface AccountUser extends ModelAttributes<typeof config> {
  account: Loadable<Account>;
}

@model("account_users", config)
class AccountUser extends Model<typeof config> {
  static current: AccountUser | null = null;

  static byId(uid: string) {
    return AccountUser.query().find(uid);
  }

  static forUser(uid: string) {
    return AccountUser.query().eq("user_id", uid).preload("account").limit(5).all();
  }

  static async createFor(adapter: SessionAdapter, user: User) {
    let account_id: string;

    const { error, data } = await adapter.rpc<{ id: string }>("insert_trial_account", {
      uid: user.id,
    });
    if (error && error.message === "User already has an account") {
      const account = await Account.query().eq("user_id", user.id).first();
      if (!account) throw new Error("User already has an account but could not find it");
      account_id = account.id!;
    } else if (error) {
      if (error) throw error;
    } else if (!data.id) {
      throw new Error("No account id returned from insert_trial_account");
    } else {
      account_id = String(data.id);
    }

    const accountUser = (await AccountUser.forUser(user.id)).find(
      (au) => au.account_id === String(account_id),
    );
    if (!accountUser) throw new Error("Could not find account user for newly created account");

    if (isNil(accountUser.name)) {
      accountUser.name = user.user_metadata?.name || "Admin";
      await accountUser.save();
    }

    return accountUser;
  }

  override canDestroy() {
    return this.role === "Manager";
  }
}

export default AccountUser;

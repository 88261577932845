import dayjs from "dayjs";
import Debug from "debug";
import { Model, ModelAttributes, collection, model, modelConfig } from "lib/oom";
import { reference } from "lib/oom/reference";
import { isNil, isPresent } from "lib/util";
import Club from "./Club";
import Game from "./Game";
import Night from "./Night";
import Player, { PlayerContainer } from "./Player";
import { setAccountId } from "./concerns/SetAccountId";

const debug = Debug("oom:model:GameSet");

const config = modelConfig(
  {
    account_id: {
      type: "string",
    },
    game_set_time: {
      type: "number",
    },
    index: {
      type: "number",
    },
    is_deleted: {
      type: "boolean",
    },
    night_id: {
      type: "string",
    },
    started_at: {
      type: "datetime",
    },
    stopped_at: {
      type: "datetime",
    },
    updated_at: {
      type: "datetime",
    },
  },
  {
    games: collection<Game>(() => Game, "game_set_id", { order: "index" }),
  },
  {},
);

interface GameSet extends ModelAttributes<typeof config> {}

@setAccountId
@model("game_sets", config)
class GameSet extends Model<typeof config> {
  static override tableName: "game_sets" = "game_sets";
  static override config = config;

  filled: boolean = false;

  get night(): Night {
    return reference(Night, this.night_id)!;
  }
  get club(): Club {
    return this.night.club;
  }

  async ensureGames(courts: number) {
    const id = this.id;
    if (!id) throw new Error("Invalid set");

    await this.games.load();
    const games = this.games;

    if (games.length > courts) {
      debug(`need to delete ${games.length - courts} games`);
      const gamesToDelete = games.slice(courts);
      await Promise.all(gamesToDelete.map((game) => game.destroy()));
      games.splice(courts, games.length - courts);
    } else if (games.length < courts) {
      debug(`need to add ${courts - games.length} games`);
      const newGames = [...Array(courts - games.length)].map(() => new Game({ game_set_id: id, index: 0 }));

      games.push(...newGames);
    }

    await Promise.all(
      games.map((game, index) => {
        game.index = index;
        return game.save();
      }),
    );
  }

  get unlockedGames() {
    return this.games.filter((game) => !game.locked);
  }

  get canClear() {
    return this.unlockedGames.some((game) => game.playerCount > 0);
  }

  clear() {
    this.withUndo(() => {
      this.unlockedGames.forEach((game) => game.clear());
      this.filled = false;
    });
  }

  get isLastStartedSet() {
    return this.night.game_sets.every((set) => set.index! <= this.index! || !set.started);
  }

  startUndo() {
    this.games.forEach((game) => game.startBatchUndo());
  }

  endUndo() {
    this.games.forEach((game) => game.endBatchUndo());
  }

  withUndo<T>(fn: () => T): T {
    this.startUndo();
    const result = fn();
    this.endUndo();
    return result;
  }

  get canUndo() {
    const canUndoStarted = this.started && this.isLastStartedSet;
    const canUndoNotStarted = !this.started && this.games.some((game) => game.canUndo);
    return canUndoStarted || canUndoNotStarted;
  }

  undo() {
    if (this.started) {
      return this.reset();
    }

    this.games.forEach((game) => game.undo());
    this.filled = false;
  }

  get canRefill() {
    return (
      this.night.players.length > 1 &&
      (this.games.every((game) => game.full()) || this.night.players.length - this.playerIds().length < 2)
    );
  }

  async refill() {
    if (!this.canRefill) return;

    this.startUndo();
    this.unlockedGames.forEach((game) => game.clear());
    await this.fill();
    this.endUndo();
  }

  async fill() {
    this.filled = true;
    this.startUndo();

    const players = (this.night.players as Player[]).filter((player) => player.active);
    const playerName = (id: string) => players.find((p) => p.id === id)?.name;

    const playerPairs = players.reduce(
      (acc, player) => {
        if (player.partner_id) {
          acc[player.id!] = player.partner_id;
        }
        return acc;
      },
      {} as Record<string, string>,
    );
    const playerIdsToPlace = this.playerIds().reduce(
      (acc, id) => {
        acc.delete(id);
        return acc;
      },
      new Set(players.map((p) => p.id)),
    );
    const playersToPlace = players.filter((player) => playerIdsToPlace.has(player.id));
    const playerGroups = players.reduce(
      (acc, player) => {
        const { id, group } = player;
        if (!id || !group) return acc;
        const groupId = group.id;
        if (!groupId) return acc;
        return { ...acc, [id]: groupId };
      },
      {} as Record<string, string>,
    );

    const groupedPlayers = createGroupedPlayers(playersToPlace);
    const groupExclusions = this.club.groupExclusions;

    // Fill games with players
    this.unlockedGames
      .slice()
      .sort((a, b) => b.playerCount - a.playerCount)
      .forEach((game) => game.fill(groupedPlayers, playerGroups, groupExclusions, playerPairs));

    const outPlayerIds = Object.values(groupedPlayers).flat();
    const outPlayers = players.filter((p) => outPlayerIds.includes(p.id!));
    const inPlayers = players.filter((p) => !outPlayerIds.includes(p.id!));

    const timesOut = players.reduce(
      (acc, player) => ({
        [player.id!]: this.night.game_sets.reduce(
          (acc, set, idx) => (idx < this.index! && !set.hasPlayer(player.id!) ? acc + 1 : acc),
          0,
        ),
        ...acc,
      }),
      {} as Record<string, number>,
    );

    // Sort them by timesOut
    outPlayers.sort((a, b) => timesOut[a.id!] - timesOut[b.id!]);
    inPlayers.sort((a, b) => timesOut[a.id!] - timesOut[b.id!]);

    // Select the outPlayers who have been out more than any inPlayer
    const outPlayersToSwap = outPlayers
      .filter((outPlayer) => inPlayers.some((inPlayer) => timesOut[inPlayer.id!] < timesOut[outPlayer.id!]))
      .map((p) => p.id!);

    const canPlayPlayers = (player: string, canPlay: string[]) => {
      const playerGroup = playerGroups[player];
      const canPlayGroups = [...new Set(canPlay.map((player) => playerGroups[player]))];
      const excludedGroups = new Set(canPlayGroups.map((group) => groupExclusions[group]).flat());
      return !excludedGroups.has(playerGroup);
    };

    const remainingOutPlayers = outPlayersToSwap.slice();

    const swapPlayer = (inPlayer: string, swapWith: string) => {
      this.unlockedGames.find((g) => g.hasPlayer(inPlayer))?.swapPlayer(inPlayer, swapWith);
      remainingOutPlayers.splice(remainingOutPlayers.indexOf(swapWith), 1);
    };

    while (outPlayersToSwap.length > 0) {
      let outPlayers: string[] = [];
      const outPlayer = outPlayersToSwap.shift()!;
      debug("trying to swap in", playerName(outPlayer));
      const partner = playerPairs[outPlayer];
      if (partner) {
        debug("they have a partner", playerName(partner));
        if (outPlayersToSwap.includes(partner)) {
          debug("who we can include");
          outPlayersToSwap.splice(outPlayersToSwap.indexOf(partner), 1);
          outPlayers = [outPlayer, partner];
        } else {
          debug("who we can't include");
          // we can't swap in one partner
          continue;
        }
      } else {
        outPlayers = [outPlayer];
      }

      for (const game of this.unlockedGames) {
        const playerIds = game.activePlayerIds();
        // Get all the players that have played more than the outPlayer
        const playersCanSwapWith = playerIds.filter((p) => timesOut[p] < timesOut[outPlayer]);
        // If there are not enough players to swap with then continue
        if (playersCanSwapWith.length < outPlayers.length) continue;
        // Sort the players by the number of times they have been out
        playersCanSwapWith.sort((a, b) => timesOut[a] - timesOut[b]);
        // We'll fill this array with the players we'll swap with from
        // playersCanSwapWith plus their partners if they have any
        let playersIWillSwapWith: string[] = [];

        while (playersIWillSwapWith.length < outPlayers.length) {
          const nextPlayer = playersCanSwapWith.shift()!;
          if (!nextPlayer) break;
          const partner = playerPairs[nextPlayer];
          if (partner) {
            debug("player", playerName(nextPlayer), "has a partner", playerName(partner));
            // If the player has a partner and the partner can be swapped with too then add them
            // to the list, but if they cannot be swapped OR we end up with too many players to swap
            // then don't try the swap at all
            if (playersCanSwapWith.includes(partner)) {
              debug("partner can be swapped with");
              playersCanSwapWith.splice(playersCanSwapWith.indexOf(partner), 1);

              if (playersIWillSwapWith.length + 2 > outPlayers.length) {
                debug("but that resulted in too many players to swap");
                continue;
              }

              playersIWillSwapWith.push(nextPlayer);
              playersIWillSwapWith.push(partner);
            } else {
              debug("partner can't be swapped with");
              continue;
            }
          } else {
            debug("player", playerName(nextPlayer), "does not have a partner");
            playersIWillSwapWith.push(nextPlayer);
          }
        }

        // We need to double check that we have the right number of players to swap with
        if (playersIWillSwapWith.length < outPlayers.length) {
          debug("not enough players to swap with during double check");
          continue;
        }
        playersIWillSwapWith = playersIWillSwapWith.slice(0, outPlayers.length);

        // Now we need to check that the group exclusions are not violated by
        // swapping these players
        const playersLeft = playerIds.filter((p) => !playersIWillSwapWith.includes(p));

        if (!outPlayers.every((p) => canPlayPlayers(p, playersLeft))) {
          debug("group exclusions violated");
          continue;
        }

        // Perform the swap and break out of the game check loop
        for (const [index, outPlayer] of outPlayers.entries()) {
          debug("swapping", playerName(outPlayer), "with", playerName(playersIWillSwapWith[index]));
          swapPlayer(playersIWillSwapWith[index], outPlayer);
        }
        break;
      }
    }

    const playerThatCanPlayPlayers = (players: string[], otherPlayers: string[]) => {
      for (let i = 0; i < players.length; i++) {
        for (let j = i + 1; j < players.length; j++) {
          // Check if both players[i] and players[j] can play with otherPlayers
          if (canPlayPlayers(players[i], otherPlayers) && canPlayPlayers(players[j], otherPlayers)) {
            return [players[i], players[j]];
          }
        }
      }
    };

    if (remainingOutPlayers.length > 1) {
      debug("trying to swap with pairs");
      // Assuming that we have done all the individual swaps possible, see if there are pair swaps possible
      const playerIds = this.activePlayerIds();

      const validPairs = playerIds
        .filter((p) => playerPairs[p])
        .reduce(
          (acc, item, idx, array) => {
            const partner = playerPairs[item];
            // Check if the pair exists in the map and in the array, and if it hasn't been added to 'seen'
            if (partner && array.includes(partner) && !acc.seen.has(item) && !acc.seen.has(partner)) {
              acc.result.push([item, partner]);
              acc.seen.add(item);
              acc.seen.add(partner);
            }
            return acc;
          },
          { result: [] as Array<string[]>, seen: new Set() },
        ).result;

      for (const pair of validPairs) {
        if (remainingOutPlayers.length < 2) break;
        const game = this.playerGame(pair[0]);
        if (!game) continue;
        const otherPlayers = game.activePlayerIds().filter((p) => !pair.includes(p));
        // find 2 items in remainingOutPlayers that satisfy canPlay
        const players = playerThatCanPlayPlayers(remainingOutPlayers, otherPlayers);
        if (players) {
          debug("swapping", playerName(players[0]), "with", playerName(pair[0]));
          debug("swapping", playerName(players[0]), "with", playerName(pair[1]));
          swapPlayer(pair[0], players[0]);
          swapPlayer(pair[1], players[1]);
          break;
        } else {
          debug("no swaps found for", playerName(pair[0]), playerName(pair[1]));
        }
      }
    }

    this.endUndo();

    await Promise.all(this.games.map((game) => game.save()));
  }

  playerGame(playerId: string) {
    return this.games.find((game) => game.playerIds.includes(playerId));
  }

  playerGameIndex(playerId: string) {
    for (let i = 0; i < this.games.length; i++) {
      const game = this.games[i];
      if (!game.players?.current) continue;
      const playerIndex = game.players.current.indexOf(playerId);
      if (playerIndex === -1) continue;
      return `${this.index}-${i}-${playerIndex}`;
    }

    return undefined;
  }

  activePlayerIds() {
    return this.games.flatMap((game) => game.activePlayerIds());
  }

  playerIds() {
    return this.games.flatMap((game) => game.playerIds).filter((p): p is string => !isNil(p));
  }

  override async save() {
    await super.save();
    if (this.games.loaded) {
      await Promise.all(this.games.map((game) => game.save()));
    }
  }

  /**
   * Game can start if the previous game has started and next game has not yet
   * started
   */
  get canStart(): boolean {
    if (isNil(this.index)) return false;

    let gameSets = this.night.game_sets;
    let firstSet = this.index === 0;
    let previousGameSetStarted = this.index !== 0 && gameSets[this.index - 1]?.started;
    let nextGameSetNotStarted = !gameSets[this.index + 1]?.started;

    return (firstSet || previousGameSetStarted) && nextGameSetNotStarted;
  }

  get running(): boolean {
    return Boolean(this.started_at && !this.stopped_at);
  }

  get started(): boolean {
    return isPresent(this.started_at);
  }

  get finished(): boolean {
    return this.started && isPresent(this.stopped_at);
  }

  get duration() {
    if (this.started_at) {
      if (this.stopped_at) {
        return Math.abs(dayjs(this.stopped_at).diff(dayjs(this.started_at)));
      } else {
        return Math.abs(dayjs().diff(dayjs(this.started_at)));
      }
    } else {
      return 0;
    }
  }

  start() {
    if (!this.started_at) {
      this.started_at = new Date();
    } else if (this.stopped_at) {
      this.started_at = new Date(new Date().valueOf() - this.duration);
    }
    this.stopped_at = undefined;

    return Promise.all([
      this.night.stop_warm_up(),
      ...this.night.game_sets.filter((set) => set.id !== this.id).map((set) => set.stop()),
      this.save(),
    ]);
  }

  stop() {
    this.stopped_at = new Date();
    return this.save();
  }

  reset() {
    this.started_at = undefined;
    this.stopped_at = undefined;
    return this.save();
  }

  hasPlayer(id: string) {
    return this.games.some((game) => game.playerIds.includes(id));
  }

  hasPlayers() {
    return this.games.some((game) => game.playerCount > 0);
  }

  playerPosition(id: string) {
    for (let i = 0; i < this.games.length; i++) {
      const game = this.games[i];
      const index = game.playerIds.indexOf(id);
      if (index !== -1) {
        return [i, index];
      }
    }
  }

  async setPlayerContainerGame(gameIndex: number, playerIndex: number, container: PlayerContainer) {
    this.startUndo();

    const { games } = this;
    const game = games[gameIndex];

    const [firstPlayer, secondPlayer] = container;

    // Determine the target slots based on the playerIndex and the number of players in the container
    const targetStartIndex = container.length === 2 ? (playerIndex < 2 ? 0 : 2) : playerIndex;

    // Store original player IDs for potential swaps
    const originalPlayerId = game.playerIds[targetStartIndex];

    // Check if the first player in the container is currently assigned to another game
    const firstPlayerOriginalPosition = this.playerPosition(firstPlayer.id!);

    // Replace player in the target slot
    game.setPlayerAt(targetStartIndex, firstPlayer.id);

    // Handle swap if the first player is from a different game
    if (firstPlayerOriginalPosition) {
      this.games[firstPlayerOriginalPosition[0]].setPlayerAt(
        firstPlayerOriginalPosition[1],
        originalPlayerId,
      );
    }

    // If there are two players in the container, handle the second player
    if (secondPlayer) {
      const originalSecondPlayerId = game.playerIds[targetStartIndex + 1];
      const secondPlayerOriginalPosition = this.playerPosition(secondPlayer.id!);

      game.setPlayerAt(targetStartIndex + 1, secondPlayer.id);

      // Handle swap if the second player is from a different game
      if (secondPlayerOriginalPosition) {
        this.games[secondPlayerOriginalPosition[0]].setPlayerAt(
          secondPlayerOriginalPosition[1],
          originalSecondPlayerId,
        );
      }
    }

    this.endUndo();
    await this.games.save();
  }

  async setPlayerGame(gameIndex: number, playerIndex: number, playerId: string) {
    const player = this.night.players.find((p) => p.id === playerId);
    if (!player) return;
    this.setPlayerContainerGame(gameIndex, playerIndex, [player]);
  }

  async removePlayerContainer(players: PlayerContainer) {
    for (const player of players) {
      await this.removePlayer(player.id!);
    }
  }

  async removePlayer(playerId: string) {
    this.games.map((game) => {
      game.removePlayer(playerId);
    });

    await this.games.save();
  }

  get title() {
    return `Set ${this.index! + 1}`;
  }

  override async create() {
    if (isNil(this.index) || this.index < 0) {
      throw new Error("Invalid set index");
    }

    return super.create();
  }
}

export default GameSet;

// Group players by their group, excluding players who are already in a game
function createGroupedPlayers(players: Player[]) {
  return players.reduce(
    (acc, player) => {
      const { id, group } = player;
      if (!id || !group) return acc;
      const groupId = group.id;
      if (!groupId) return acc;
      return { ...acc, [groupId]: [...(acc[groupId] || []), id] };
    },
    {} as Record<string, string[]>,
  );
}

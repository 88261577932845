import Session from "Session";
import Invite from "lib/models/Invite";
import { Model } from "lib/oom";
import { SubscriptionManager } from "lib/oom/SubscriptionManager";
import NullDatabaseAdapter from "lib/oom/adapters/NullDatabaseAdapter";
import SupabaseDatabaseAdapter from "lib/oom/adapters/SupabaseDatabaseAdapter";
import TauriDatabaseAdapter from "lib/oom/adapters/TauriDatabaseAdapter";
import { createSupabase } from "lib/supabaseClient";
import ReactDOM from "react-dom/client";
import "./index.css";
import * as Sentry from "@sentry/react";
import tauri from "lib/tauri";
import { MockTauri } from "lib/tauri/MockTauri";

if (import.meta.env.VITE_TEST === "1") {
  console.log("Running in test mode");
  localStorage.setItem("debug", "*");
}

// @ts-ignore
if (window.__TAURI_TEST_MODE) {
  MockTauri.setup();
} else {
  if (import.meta.env.VITE_TEST !== "1") {
    Sentry.init({
      dsn: "https://83cf540db2b65c3f58d5494e4824d7ba@o1057895.ingest.sentry.io/4506555760246784",
      integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
      // Performance Monitoring
      tracesSampleRate: 0.3, //  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      environment: import.meta.env.MODE,
      enabled: true, //import.meta.env.MODE !== "development",
    });
  }

  if (tauri.enabled) {
    const tauriAdapter = new TauriDatabaseAdapter();
    Model.adapter = tauriAdapter;
    Invite.adapter = new NullDatabaseAdapter();
  } else {
    const supabase = createSupabase();
    Model.adapter = new SupabaseDatabaseAdapter(supabase);
    SubscriptionManager.supabase = supabase;
  }

  ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(<Session />);
}

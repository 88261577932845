import ApplicationController from "lib/mvc";
import SessionController from "./SessionController";
import tauri from "lib/tauri";

interface State {
  syncState: "first_sync" | "idle" | "syncing";
  syncProgress: number;

  uploadState: "idle" | "uploading";
  uploadProgress?: { count: number; index?: number };
  rowsToSync: number;
}

interface ProgressEvent {
  count: number;
  index?: number;
  rows_left: number;
}

interface StartEvent {
  count: number;
}
type FinishedEvent = { count: number; rows_left: number };

class TauriController extends ApplicationController<State> {
  signedInAtSync?: boolean;

  override async initialize(props: unknown) {
    this.signedInAtSync = this.get(SessionController).signedIn;
    this.state.syncState = "idle";
    this.state.syncProgress = 0;
    this.state.uploadState = "idle";

    await tauri.listen<StartEvent>("mixitone:sync_started", (event) => {
      this.debug("sync_started", event);
      this.state.syncProgress = 0;
      this.state.rowsToSync = event.payload.count;

      if (!this.signedInAtSync) {
        this.state.syncState = "first_sync";
      } else {
        this.state.syncState = "syncing";
      }
    });
    await tauri.listen("mixitone:sync_progress", (event) => {
      this.debug("sync_progress", event);
      const { total, progress } = event.payload as { total: number; progress: number };
      this.state.syncProgress = Math.round((progress / total) * 100);
    });
    await tauri.listen("mixitone:sync_finished", (event) => {
      this.debug("sync_finished", event);
      this.state.syncState = "idle";
      this.state.syncProgress = 100;
    });
    await tauri.listen("mixitonedb:change", () => {
      if (!this.state.uploadProgress) {
        this.state.uploadProgress = { count: 0 };
      }
      this.state.uploadProgress.count += 1;
    });
    await tauri.listen<StartEvent>("mixitone:upload:started", (event) => {
      this.debug("started", event);
      this.state.uploadState = "uploading";
      this.state.uploadProgress = { count: event.payload.count };
      this.state.rowsToSync = event.payload.count;
    });
    await tauri.listen<FinishedEvent>("mixitone:upload:finished", (event) => {
      this.debug("finished", event);
      this.state.uploadState = "idle";
      this.state.uploadProgress = { count: event.payload.count };
      this.state.rowsToSync = event.payload.rows_left;
    });
    await tauri.listen<ProgressEvent>("mixitone:upload:progress", (event) => {
      this.debug("progress", event);
      this.state.uploadState = "uploading";
      this.state.uploadProgress = { count: event.payload.count, index: event.payload.index };
      this.state.rowsToSync = event.payload.rows_left;
    });

    this.observe(() => {
      // @ts-ignore
      window.tauriUploadState = this.state.uploadState;
      // @ts-ignore
      window.tauriSyncState = this.state.syncState;
    });
  }

  get syncing() {
    return ["first_sync", "syncing"].includes(this.state.syncState);
  }

  get ready() {
    return ["idle", "syncing"].includes(this.state.syncState);
  }
}

export default TauriController;

import ApplicationController from "lib/mvc";
import RouteController from "./RouteController";
import { MessageType, MessagesController } from "Messages";

export default class MixitoneController<State extends object, Props = {}> extends ApplicationController<
  State,
  Props
> {
  async actionForward(to: string, { replace }: { replace?: boolean } = {}) {
    this.debug("Forwarding to", to, { replace });
    return this.get(RouteController).actionForward(to, { replace });
  }

  async actionBack() {
    this.debug("Going back");
    return this.get(RouteController).actionBack();
  }

  actionAddMessage(body: React.ReactNode, type: MessageType) {
    return this.get(MessagesController).actionAddMessage(body, type);
  }
}

import React from "react";
import { twMerge } from "tailwind-merge";

const Pulse: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <span
      className={twMerge("absolute right-0 top-0 z-10 -mr-1 -mt-1 flex h-3 w-3 duration-500 transition-opacity", className)}
    >
      <span className="absolute inline-flex w-full h-full bg-orange-600 rounded-full opacity-75 animate-ping"></span>
      <span className="relative inline-flex w-3 h-3 bg-orange-400 rounded-full"></span>
    </span>
  );
};

export default Pulse;

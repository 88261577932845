import { ApplicationView } from "lib/mvc";
import { Spinner } from "./Spinner";

const LoadingScreen: React.FC = () => {
  return (
    <div className="flex w-full h-[50vh] flex-row items-center">
      <div className="flex w-full flex-col items-center">
        <Spinner size={100} />
      </div>
    </div>
  );
};

export default ApplicationView(LoadingScreen);

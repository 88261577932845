import { Database } from "lib/database.types";
import { Model, ModelAttributes, collection, model, modelConfig } from "lib/oom";
import AccountUser from "./AccountUser";
import Club from "./Club";
import Invite from "./Invite";
import { isNil } from "lib/util";
import tauri from "lib/tauri";
import { createSupabase } from "lib/supabaseClient";
import { withCachedPromise } from "controllers/concerns/withCachedPromise";
import TauriDatabaseAdapter from "lib/oom/adapters/TauriDatabaseAdapter";

const config = modelConfig(
  {
    created_at: {
      type: "datetime",
    },
    is_deleted: {
      type: "boolean",
    },
    is_temporary: {
      type: "boolean",
    },
    status: {
      type: "enum",
      enumType: "Trial" as Database["public"]["Enums"]["account_status"],
    },
    updated_at: {
      type: "datetime",
    },
    user_id: {
      type: "string",
    },
  },
  {
    accountUsers: collection(() => AccountUser, "account_id"),
    invites: collection(() => Invite, "account_id"),
    clubs: collection(() => Club, "account_id"),
  },
  {},
);

interface Account extends ModelAttributes<typeof config> {}

@model("accounts", config)
class Account extends Model<typeof config> {
  static _current: Account | undefined;
  static get current() {
    return AccountUser.current?.account || this._current;
  }
  static set current(account: Account | null | undefined) {
    if (AccountUser.current && account) {
      throw new Error("Cannot set current account when there is already a current account");
    }
    this._current = account ?? undefined;
  }

  @withCachedPromise(200)
  async creditBalance() {
    if (tauri.enabled) {
      let adapter = Account.adapter as TauriDatabaseAdapter;
      const response = await adapter.invoke_select(
        "select sum(balance) as balance from credits where account_id = ? and (expires_at is null or expires_at > ?)",
        [this.id, "today"],
      );
      return response[0].balance ?? 0;
    } else {
      const response = await createSupabase().rpc("calculate_unexpired_balance", {
        account_id_param: this.id!,
      });
      return response.data ?? 0;
    }
  }

  get isAnonymous() {
    return this.is_temporary && isNil(this.user_id);
  }
  get isSignedUp() {
    return !this.isAnonymous;
  }

  async owner(): Promise<AccountUser | undefined> {
    await this.accountUsers.load();
    return this.accountUsers.find((row) => row.user_id === this.user_id);
  }
}

export default Account;
